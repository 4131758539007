@import '~assets/styles/variables';
@import '~assets/styles/mixins';
@import '~assets/styles/keyframes';

.wrapper {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-color: rgba($primary-bg, 0.8);
	z-index: 223;
	overflow-y: auto;
	padding: 0 2vw;
	@include changeOpacityToVisibleAnimation;

	&.fixed {
		position: fixed;
		top: 100px;
	}

  &.withoutTop {
    top: 0;
    padding: 0;

    & .content__container {
      height: auto;
      margin: 4.5vh auto 0;
    }
  }

	.button_cross {
		right: 30px;
		top: 30px;
		position: absolute;
		z-index: 10;
	}

	&.close {
		@include changeOpacityToUnvisibleAnimation;
	}

	.content__container {
		max-width: 912px;
		width: 100%;
		height: calc(100vh - 100px - 7.9vh);
		background-color: #fff;
		opacity: 1;
		box-shadow: 2px 2px 4px rgba(241, 243, 255, 0.3);
		border-radius: 10px;
		margin: 0 auto;
		margin-top: 4.5vh;
		padding-top: 7.9vh;
		padding-bottom: 5vh;
		padding-left: 4.95vw;
		padding-right: 4.95vw;
		position: relative;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
	}

	&.padding_disabled > .content__container {
		padding: 0;
		display: flex;
		flex-direction: column;
		align-items: center;

		& > div {
			min-height: auto;
		}
	}
}
