@import '~assets/styles/variables';
@import '~assets/styles/mixins';


.storeTable {
  border-collapse: collapse;
  position: relative;

  &_wrapper {
    width: 100%;
    min-height: calc(100% - 200px);
    height: 100%;
    overflow-y: hidden;
    overflow-x: auto;
  }

  &_thead, &_tbody {
    &_photo {
      min-width: 80px;
    }

    &_title {
      min-width: 226px;
      max-width: 226px;
    }

    &_price {
      min-width: 132px;
      max-width: 132px;
    }

    &_createdDate {
      min-width: 254px;
    }

    &_expirationTime {
      min-width: 254px;
    }

    &_couponsLeft {
      min-width: 132px;
      max-width: 132px;
    }

    &_websiteLink {
      min-width: 126px;
    }

    &_visibility {
      min-width: 126px;
    }
    &_actions {
      min-width: 58px;
    }
  }

  &_thead {
    height: 40px;

    tr {
      background-color: #F1F3FF;
    }

    th {
      background-color: #F1F3FF;
      padding: 10px 16px;
      color: #214ba5;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.208px;
      cursor: default;
    }

    &_withSort {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;

      &_icon {
        display: flex;
        flex-direction: column;
        height: 16px;

        svg path {
          fill: #AFB7FB;
        }
      }

      &_ASC, &_DESC {
        path {
          fill: $primary-main !important;
        }
      }
    }

    &_photo {
      border-radius: 8px 0 0;
    }

    &_actions {
      border-radius: 0 8px 0 0;
    }
  }

  &_tbody {
    tr {
      height: 100px;
      border-top: 1px solid white;
      border-bottom: 1px solid white;
    }

    td {
      padding: 10px 16px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.208px;
      color: $text-main;

      &:first-child {
        padding: 10px;
      }
    }

    &_suspended {
      td {
        background-color: $primary-light-orange-main-bg;
      }
    }

    &_title {
      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 131%;
        letter-spacing: -0.14px;

        @include multilineThreeDots(1)
      }
    }

    &_price, &_couponsLeft {
      span {
        @include multilineThreeDots(1)
      }
    }

    &_createdDate, &_expirationTime {
      &_wrapper {
        display: flex;
        padding: 4px 8px;
        align-items: center;
        gap: 8px;
        border-radius: 30px;
        width: max-content;

        span {
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: -0.3px;
        }
      }
    }

    &_createdDate {
      &_wrapper {
        background-color: $primary-light02;
        color: $primary-main;
      }
    }

    &_expirationTime {
      &_wrapper {
        background-color: $primary-light-orange-bg;
        color: $primary-light-orange-text;

        svg path {
          fill: $primary-light-orange-text;
        }
      }
    }

    &_websiteLink, &_visibility {
      svg {
        cursor: pointer;
      }
    }
  }

  &__success, &__error {
    display: inline-flex;
    padding: 8px 12px;
    align-items: flex-start;
    gap: 8px;
    width: 196px;
    height: 51px;

    border-radius: 8px;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      &_title {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      &_message {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  &__success {
    border: 1px solid #5CC469;
    background: #DBF4E9;
    width: 274px;

    &__content {
      &_title {
        color: #6C6E7E;
      }

      &_message {
        color: #6C6E7E;
      }
    }
  }

  &__error {
    border: 1px solid #C32B2B;
    background: #FEE;


    &__content {
      &_title {
        color: #6C6E7E;
      }

      &_message {
        color: #6C6E7E;
      }
    }
  }
}

.overflow__hidden {
  overflow: hidden !important;

  & table {
    display: none;
  }
}

.overflow_y__hidden {
  overflow-y: hidden !important;
}

.noItemsContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 912px;
  flex-direction: column;
  align-items: center;
  margin: auto;
  gap: 60px;
  box-shadow: 2px 2px 4px rgba(241, 243, 255, 0.3);
  border-radius: 10px;
  background-color: #fff;


  &__gradient {
    border-radius: 10px 10px 0 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 73%;
    background: linear-gradient(180deg, #fff4e4 0%, rgba(255, 244, 228, 0) 100%);
  }

  .noItemsImage {
    width: 320px;
    height: 320px;
    z-index: 2;
  }

  .noItemsTextContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;

    .noItemsTitle {
      font-size: 24px;
      font-weight: 500;
      line-height: 26.4px;
      text-align: center;

      color: #1c2f4c;
    }

    .noItemsText {
      padding-bottom: 4vh;
      font-size: 14px;
      font-weight: 400;
      line-height: 18.2px;
      text-align: center;

      color: #000000;
    }
  }
}
