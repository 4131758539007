@import '~assets/styles/variables';

.specialOfferForm {
  display: flex;
  flex-direction: column;
  gap: 32px;

  &__item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: unset;
    margin-bottom: unset !important;
    width: 100%;

    label {
      margin-bottom: unset !important;
    }

    &__fieldDescription {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.3px;
      color: #5B768B;
    }

    &_label {
      font-size: 16px;
      font-weight: 500;
      line-height: 18.96px;
    }

    &_required {
      color: $required;
    }

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }

    &_subFields {
      display: flex;
      flex-direction: column;
      margin-top: 12px;

      &__item {
        display: flex;
        gap: 8px;
        align-items: center;
      }

      &__storeItem {
        display: flex;
        gap: 8px;
        width: 100%;

        &_tooltip {
          top: 16px !important;
          z-index: 999999;
          font-family: "Rubik", sans-serif;

          & > div {
            width: 100%;
            max-width: 100%;

            span {
              top: 37px !important;
            }
          }
        }

        & > svg {
          min-width: 23.23px;
          margin-top: 19.5px;
        }

        &.disabled {
          opacity: 0.5;
          cursor: default;
        }
      }
    }

    &__textfield {
      & input:disabled {
        background-color: transparent;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 28px;

    &_actions {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &__right {
        display: flex;
        gap: 24px;
      }
    }

    &_button {
      width: 200px !important;
      max-width: 200px !important;
    }
  }
}
