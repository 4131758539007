@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.buttons_block {
    margin-top: 44px;
    display: flex;
    justify-content: end;

    button {
        margin-right: 19px;

        &:last-child {
            margin-right: 10px;
        }
    }
}

.whiteContainer {
    padding: unset;
}

.checkboxWrapper {
    display: flex;
    justify-content: center;
}


.noItemsContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 912px;
  flex-direction: column;
  align-items: center;
  margin: auto;
  gap: 4vh;
  background-color: #fff;
  box-shadow: 2px 2px 4px rgba(241, 243, 255, 0.3);
  border-radius: 10px;


  &__gradient {
    border-radius: 10px 10px 0 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 73%;
    background: linear-gradient(180deg, #fff4e4 0%, rgba(255, 244, 228, 0) 100%);
  }

  .noItemsImage {
    width: 24%;
    height: 24%;
    z-index: 2;
  }

  .noItemsTextContainer {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    align-items: center;

    .noItemsTitle {
      font-size: 24px;
      font-weight: 500;
      line-height: 26.4px;
      text-align: center;

      color: #1c2f4c;
    }

    .noItemsText {
      padding-bottom: 4vh;
      font-size: 14px;
      font-weight: 400;
      line-height: 18.2px;
      text-align: center;

      color: #000000;
    }
  }
}
