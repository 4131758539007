@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.couponsTable {
  border-collapse: collapse;
  position: relative;
  width: 100%;

  &_wrapper {
    width: 100%;
    overflow-y: auto;
    //overflow-x: hidden;
    scroll-margin-left: 20px;


    &::-webkit-scrollbar-thumb {
      background: $primary-light02;
      transition: all 0.3s;

      &:hover {
        transition: all 0.3s;
        background: $primary-main;
      }
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }

  &__thead {
    height: 40px;
    background-color: #F1F3FF;

    th {
      position: sticky;
      top: 0;
    }

    tr {
      background-color: #F1F3FF;
    }

    &__ID {
      border-radius: 8px 0 0;
    }

    &__Status {
      &__sortable {
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;

        &_title {
          color: #214ba5 !important;
          font-size: 12px !important;
          font-style: normal !important;
          font-weight: 700 !important;
          line-height: 20px !important;
          letter-spacing: -0.208px !important;
        }

        svg {
          path {
            fill: #AFB7FB;
          }
        }

        &_active {
          svg {
            path {
              fill: $primary-main;
            }
          }
        }

        &__select {
          display: flex;
          flex-direction: column;
          width: 96px;
          background-color: white;

          box-shadow: 0 0 20px 0 rgba(33, 75, 165, 0.15);
          border-radius: 8px;
          border: 1px solid $primary-light;

          &_item {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 8px 12px;
            width: 100%;

            font-size: 12px !important;
            font-style: normal !important;
            font-weight: 700 !important;
            line-height: 20px !important;
            cursor: pointer;

            color: $text-main;

            &:first-child {
              border-radius: 8px 8px 0 0;
            }

            &:last-child {
              border-radius: 0 0 8px 8px;
            }

            &:hover {
              background-color: $primary-light02 !important;
            }

            &_active {
              background-color: $primary-light02 !important;
            }
          }
        }
      }
    }

    &__Actions {
      border-radius: 0 8px 0 0;
    }
  }

  &__tbody {
    tr {
      height: 52px;
    }

    &__Actions {
      padding: 6px 16px;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        height: 60px;

        svg {
          cursor: pointer;
        }
      }
    }
  }

  &__thead, &__tbody {
    th {
      background-color: #F1F3FF;
      padding: 10px 16px;
      color: #214ba5;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.208px;
      cursor: default;
    }

    td {
      padding: 4px 16px;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: -0.208px;
      color: $text-black;

      span {
        @include multilineThreeDots(1);
        line-break: anywhere;
      }
    }

    &__ID {
      min-width: 128px;
      max-width: 128px;

      span {
        @include multilineThreeDots(1)
      }
    }

    &__Coupons {
      min-width: 296px;
      max-width: 296px;

      span {
        @include multilineThreeDots(1)
      }
    }

    &__Status {
      min-width: 308px;
      max-width: 316px;

      span {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.208px;
        color: $text-black;
      }

      &_link {
        color: $primary-main;
        font-weight: 400;
        line-height: 131%; /* 20.96px */
        letter-spacing: -0.16px;
        text-decoration: none;
      }
    }

    &__Actions {
      min-width: 88px;
      max-width: 88px;
    }
  }
}

.overflow__hidden {
  & table {
    display: none;
  }
}

.noItemsContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 912px;
  flex-direction: column;
  align-items: center;
  margin: auto;
  gap: 60px;
  box-shadow: 2px 2px 4px rgba(241, 243, 255, 0.3);
  border-radius: 10px;
  background-color: #fff;


  &__gradient {
    border-radius: 10px 10px 0 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 73%;
    background: linear-gradient(180deg, #fff4e4 0%, rgba(255, 244, 228, 0) 100%);
  }

  .noItemsImage {
    width: 320px;
    height: 320px;
    z-index: 2;
  }

  .noItemsTextContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;

    .noItemsTitle {
      font-size: 24px;
      font-weight: 500;
      line-height: 26.4px;
      text-align: center;

      color: #1c2f4c;
    }

    .noItemsText {
      padding-bottom: 4vh;
      font-size: 14px;
      font-weight: 400;
      line-height: 18.2px;
      text-align: center;

      color: #000000;
    }
  }
}
