@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.donationsTable {
  border-collapse: collapse;
  position: relative;
  width: 100%;

  &_wrapper {
    width: 100%;
    overflow-y: auto;
    //overflow-x: hidden;
    scroll-margin-left: 20px;


    &::-webkit-scrollbar-thumb {
      background: $primary-light02;
      transition: all 0.3s;

      &:hover {
        transition: all 0.3s;
        background: $primary-main;
      }
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }

  &__thead {
    height: 40px;
    background-color: #F1F3FF;

    tr {
      background-color: #F1F3FF;
    }

    th {
      position: sticky;
      top: 0;
    }

    &__Avatar {
      border-radius: 8px 0 0;
    }

    &__Actions {
      border-radius: 0 8px 0 0;
    }
  }

  &__tbody {
    tr {
      height: 52px;
    }

    &__Avatar {
      min-height: unset !important;
      padding: unset;

      h1 {
        margin-bottom: 30px;
      }

      &:not(div) {
        padding: 10px 16px !important;
      }

      & > img {
        width: 48px;
        height: 48px;
      }
    }

    &__FullName {
      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.208px;
      }
    }

    &__Date {
      div {
        display: flex;
        padding: 4px 8px;
        align-items: center;
        gap: 8px;
        border-radius: 30px;
        background: #E4E7FF;
        width: fit-content;

        span {
          font-family: Rubik, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: -0.3px;
          color: #214BA5;
        }
      }
    }
  }

  &__thead, &__tbody {
    th {
      background-color: #F1F3FF;
      padding: 10px 16px;
      color: #214ba5;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.208px;
      cursor: default;
    }

    td {
      height: 100px;
      padding: 4px 16px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      color: $text-main;

      span {
        @include multilineThreeDots(1);
        line-break: anywhere;
      }
    }

    &__Avatar {
      min-width: 80px;
      max-width: 80px;
    }

    &__FullName {
      min-width: 220px;
      max-width: 220px;

      span {
        @include multilineThreeDots(1)
      }
    }

    &__ShownAs {
      min-width: 160px;
      max-width: 160px;
    }

    &__Sum {
      min-width: 160px;
      max-width: 160px;
    }

    &__Date {
      min-width: 254px;
      max-width: 254px;
    }

    &__Email {
      min-width: 212px;
      max-width: 212px;
    }

    &__Actions {
      min-width: 60px;
      max-width: 60px;

      &_tooltip {
        width: 79px;
        z-index: 999999;

        & > div {
          max-width: 100%;
          width: 100%;

          color: $text-main;
          text-align: center;

          /* Caption/Reg  12 */
          font-family: "Rubik", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 166.667% */
          letter-spacing: -0.3px;

          & > span {
            bottom: -9px !important;
          }
        }
      }
    }
  }
}

.overflow__hidden {
  & table {
    display: none;
  }
}
