.start__fields,
.end__fields {
  width: 100%;
  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 4px;
  }
}

.end__fields {
  margin-bottom: 40px;
}

.wrapper {
  display: flex;
  gap: 8px;
}

.field__date_start,
.field__date_end {
  width: 100%;
  & > div > span {
    position: absolute;
    left: 0px;
    bottom: -18px;
  }
}

.field__date_end {
  & > div > span {
    bottom: -18px;
  }
}

.field__time_start,
.field__time_end {
  & > div > span {
    position: absolute;
    left: 0px;
    bottom: -18px;
  }
}

.without__time {
  width: 100%;
  & > div > span {
    bottom: -20px;
    max-width: 180px;
  }
}
