@import '~assets/styles/variables';

.mediaInput {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &__image {
      cursor: pointer;
      width: 150px;
      height: 150px;
      object-fit: cover;
      border-radius: 20px;

      &_hover {
        position: relative;
        width: 150px;
        height: 150px;
        cursor: pointer;

        &:before {
          content: '';
          position: absolute;
          width: inherit;
          height: inherit;
          top: 0;
          left: 0;
          border-radius: 20px;
          transition: all 0.3s;
        }

        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 36px;
          height: 36px;
        }

        &:hover {
          transition: all 0.3s;

          &:before {
            background: rgba(238, 240, 255, 0.60);
            backdrop-filter: blur(3px);
            transition: all 0.3s;
          }

          &:after {
            background: url('../../../assets/icons/upload-trash.svg');
          }
        }
      }
    }

  &_label {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.96px;
  }

  &_placeholder {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    letter-spacing: -0.3px;
    color: $text-light;
  }

  &_required {
    color: $required;
  }
}
