@import '~assets/styles/variables';

.donationItemForm {
  display: flex;
  flex-direction: column;
  gap: 32px;

  &__item {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &_label {
      font-size: 16px;
      font-weight: 500;
      line-height: 18.96px;
    }

    &_required {
      color: $required;
    }

    &_pickers {
      display: flex;
      gap: 24px;

      &_field {
        width: 240px;
        max-width: 240px;
      }

      &_item {
        color: $text-main;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 131%; /* 23.58px */
        letter-spacing: -0.18px;

        & > input {
          padding: 8px 16px !important;
        }
      }
    }

    &__fieldDescription {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.3px;
      color: #5B768B;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 24px;
    margin-top: 28px;

    &_button {
      display: flex;
      justify-content: center;
      width: 200px !important;
      max-width: 200px !important;

      &_loader {
        width: 46px;
        margin: unset !important;
      }
    }
  }
}
