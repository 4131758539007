@import '~assets/styles/variables';

.donationsInfoPopup {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 80px 52px 52px 52px;
  width: 100%;
  gap: 40px;
  overflow: auto;
  scrollbar-width: none;

  &__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    letter-spacing: -0.208px;
    color: $text-main;
  }

  &__close {
    position: absolute;
    padding: 5.5px;
    top: 24px;
    right: 24px;
    width: 24px;
    height: 24px;

    img {
      width: 13px;
      height: 13px;
    }
  }
}
