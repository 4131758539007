@import '~assets/styles/mixins';

.statusPreview {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 360px;
  height: 844px;
  background: rgba(0, 0, 0, 0.20);
  font-family: "Plus Jakarta Sans";

  &__header {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    padding: 29px 16px 29px 16px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.00) 100%);
    z-index: 1;

    &__details {
      display: flex;
      align-items: center;

      &_back {
        cursor: pointer;
        width: 40px;
        height: 40px;
      }
      &_image {
        width: 42px;
        height: 42px;
        position: relative;
        object-fit: cover;
        margin-left: 15px;
        border-radius: 42px;
        border: 1px solid #FFF;
        background: #FFF;
      }

      &__org {
        display: flex;
        flex-direction: column;
        margin-left: 8px;

        &_title {
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          color: #ffffff;
          word-wrap: break-word;
          max-width: 100%;
          @include multilineThreeDots(1);
          line-break: anywhere;
        }
        &_startDate {
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          color: #ffffff;
        }
      }
    }
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    //object-fit: cover; /* Ensures video covers the entire section */
    z-index: 0;
  }

  &__footer {
    display: flex;
    min-width: 328px;
    min-height: 104px;
    padding: 32px 24px;
    margin: 0 16px 40px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex-shrink: 0;
    z-index: 1;

    border-radius: 16px;
    border: 0.5px solid  #FFF;
    background: rgba(0, 0, 0, 0.15);

    &__caption {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      color: #ffffff;
      word-wrap: break-word;
      max-width: 100%;
      @include multilineThreeDots(2);
      line-break: anywhere;
    }

    &__link {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      overflow: hidden;
      color: #FFF;
      text-overflow: ellipsis;
      word-wrap: break-word;
      max-width: 100%;
      @include multilineThreeDots(2);

      &_wrap {
        display: flex;
        align-items: flex-end;
        gap: 4px;
        align-self: stretch;
      }

      &_img {
        min-width: 16px;
        min-height: 16px;
      }
    }
  }
}
