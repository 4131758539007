@import '~assets/styles/variables';

.donationItemPopup {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 80px 52px 52px 52px;
  width: 100%;
  gap: 32px;
  overflow: auto;
  scrollbar-width: none;

  &__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    letter-spacing: -0.208px;
    color: $text-main;
  }

  &__close {
    position: absolute;
    padding: 5.5px;
    top: 24px;
    right: 24px;
    width: 24px;
    height: 24px;

    img {
      width: 13px;
      height: 13px;
    }
  }

  &__switch {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: 8px;

    &_button {
      width: 200px !important;
      height: 44px !important;
      padding: 10px 16px !important;
      font-size: 18px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 131% !important;
      letter-spacing: -0.18px !important;
      margin: unset !important;

      color: $text-light !important;
      border: unset !important;

      &_active {
        color: $primary-main !important;
        border: 1px solid var(--Primary-Blue-1, #214BA5) !important;
        font-weight: 500 !important;
      }
    }
  }
}
