@import '~assets/styles/mixins';

.specialOfferPreview {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 52px 52px 52px 52px;
  width: 100%;
  gap: 10px;
  overflow: auto;
  scrollbar-width: none;

  &__close {
    position: absolute;
    padding: 5.5px;
    top: 24px;
    right: 24px;
    width: 24px;
    height: 24px;

    img {
      width: 13px;
      height: 13px;
    }
  }

  &__note {
    color: #5B768B;
    font-family: Rubik, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.3px;
    width: 328px;
  }

  &__item {
    position: relative;
    display: grid;
    padding: 16px;
    width: 328px;
    height: 164px;
    gap: 28px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    border-radius: 12px;

    &_noImage {
      background: linear-gradient(117deg, #5232A1 3.58%, #AB89FF 107.3%) !important;
    }

    &_badge {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-flex;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      min-height: 40px;
      max-width: 100% !important;

      border-radius: 12px 0 12px 0;
      background: linear-gradient(294deg, #FFB64A 8.03%, #FFD34A 85.42%, #FFE24A 105.62%);

      span {
        color: #714108;

        font-family: "Gotham", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        letter-spacing: -0.16px;

        @include multilineThreeDots(1);
      }
    }

    &_offerIcon {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 12px 0 12px 0;
    }

    &_left {
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: space-between;
      height: 100%;
    }

    &_right {
      width: 100%;
      height: 100%;
      border-radius: 12px;
      aspect-ratio: 2 / 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
      }
    }
  }

  &__title {
    margin-top: 39.42px;
    font-family: "Gotham", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2; /* 120% */
    letter-spacing: -1.6px;
    color: #ffffff;
    max-width: 62% !important;

    @include multilineThreeDots(2);
  }

  &__button {
    display: flex;
    padding: 8px 8px 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: fit-content;
    max-width: 296px;
    border-radius: 8px;
    background: #ffffff;

    span {
      color: #4B5EF5;
      font-family: "Gotham", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      max-width: 100%;
      @include multilineThreeDots(1);
    }

    svg {
      min-width: 16px;
    }
  }
}
