@import '~assets/styles/variables';

.dialog {
  position: relative;

  &__header {
    display: flex;
    justify-content: end;
    padding: 30px;

    &_button {
      padding: 0;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 6px;
    overflow: auto;
    padding-bottom: 10px;

    &_right {
      margin-right: 52px;
    }
  }

  &__title {
    font-size: 24px;
    color: $text-main;
    font-weight: 500;
    line-height: 26.4px;
    letter-spacing: -0.208px;
    margin: 0 52px;
  }

  &__actions {
    display: flex;
    gap: 60px;
    justify-content: center;
    padding: 50px 52px 52px 52px;
    &_button {
      width: 240px;
    }
  }
}
