.statusMedia {
  position: relative;
  width: 48px;
  height: 48px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &_circle {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  &_video {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &_play {
    position: absolute;
    top: 52%;
    left: 52%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    color: white;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}