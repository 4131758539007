@import '~assets/styles/variables';
@import '~assets/styles/mixins';

.specialOfferTable {
  border-collapse: collapse;
  position: relative;
  width: 100%;

  &__header {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    &_createButton,
    &_editButton {
      height: 44px !important;
    }

    &_createButton {
      width: 103px !important;
    }

    &_editButton {
      width: 65px !important;
    }
  }

  &_wrapper {
    display: flex;
    flex-direction: column;
    gap: 36px;
    width: 100%;
    overflow-y: auto;
    scroll-margin-left: 20px;

    &::-webkit-scrollbar-thumb {
      background: $primary-light02;
      transition: background 0.3s;

      &:hover {
        background: $primary-main;
      }
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }

  &__thead {
    height: 40px;

    &__Photo {
      border-radius: 8px 0 0 0;
    }

    &__Actions {
      border-radius: 0 8px 0 0;
    }
  }

  &__tbody {
    tr {
      height: 52px;
    }

    &_suspended {
      td {
        background-color: $primary-light-orange-main-bg;
      }
    }

    &__ButtonName span {
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: "Rubik", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      letter-spacing: -0.208px;
    }

    &__Photo {
      padding: unset;

      &:not(div) {
        padding: 10px 16px !important;
      }

      & > img {
        width: 48px;
        height: 48px;
      }
    }
  }

  &__thead,
  &__tbody {
    th,
    td {
      font-family: 'Rubik', sans-serif;
      padding: 10px 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: -0.208px;
      font-feature-settings: 'liga' off, 'clig' off;
    }

    th {
      background-color: #F1F3FF;
      color: #214ba5;
      font-size: 12px;
      cursor: default;
    }

    td {
      height: 100px;
      font-size: 14px;
      color: $text-main;

      span {
        @include multilineThreeDots(1);
        line-break: anywhere;
      }
    }

    &__Photo {
      min-width: 80px;
    }

    &__Title {
      min-width: 146px;

      span {
        @include multilineThreeDots(1);
      }
    }

    &__ButtonName {
      min-width: 258px
    }

    &__Visibility {
      min-width: 126px;
    }

    &__Url {
      min-width: 128px;
    }

    &__Actions {
      min-width: 58px;
    }
  }
}

.overflow__hidden {
  table {
    display: none;
  }
}

.overflow_y__hidden {
  overflow-y: hidden;
}
