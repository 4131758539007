@import '~assets/styles/variables';

.container {
	line-height: 18px;
	background-color: $primary-bg;
	color: $primary-main;
	font-weight: 700;
	// display: flex;
	// justify-content: space-between;
	// align-items: center;
	display: grid;
    grid-gap: 10px;
	padding: 0 15px;
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
}

.volunteers {
	@extend .container;
	grid-template-columns: 2fr 4fr 4fr 4fr 1fr 1fr 3fr 3fr 1fr 2fr 3fr;
}

.oppotunities {
	@extend .container;
	grid-template-columns: 2fr 3fr 2fr 2fr 2fr 3fr 2fr 2fr 1fr;
}

.organizations {
	@extend .container;
	grid-template-columns: 2fr 3fr 3fr 3fr 2fr 4fr 4fr 2fr 2fr 2fr 2.5fr 1fr 2fr;
}

.invites {
  @extend .container;
	grid-template-columns: 1fr 2fr 2fr 3fr 2fr 1fr 1fr 2fr;
}

.oppotunitiesVolunteer {
	@extend .container;
	grid-template-columns: 3fr 2fr 2fr 2fr 4fr 3fr;
}

.viewMore {
	@extend .container;
	grid-template-columns: 3fr 3fr 2fr 2fr 4fr 3fr;
}

.users {
	@extend .container;
	// grid-template-columns: 2fr 2fr 2fr 4fr 2fr 2fr;
	grid-template-columns: 1fr 2fr 2fr 2fr 3fr;
}

.roles {
	@extend .container;
	grid-template-columns: 4fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
