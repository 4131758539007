.main {
  max-width: 912px;
  width: 100%;
  height: 100%;
  padding: 42px 95px;
  background-color: #fff;
  margin: 0 auto;
  box-shadow: 2px 2px 4px rgba(241, 243, 255, 0.3);
  border-radius: 10px;
  overflow-y: auto;
}

@media (max-width: 480px) {
  .main {
    padding: 21px 32px;
  }
}
