@import '~assets/styles/mixins';

.storeItems {
  @include orgPage;

  display: flex;
  flex-direction: column;

  &_title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    color: #1C2F4C;
  }

  &_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    gap: 36px;
    padding: 28px 32px;
    width: 100%;
    background-color: #fff;
    height: fit-content;
  }

  &_tabs {
    height: 62px;
    overflow-x: unset;
    margin-top: 40px;
    gap: 8px;

    &_item {
      padding: 20px 24px;
      width: 190px;
      height: 62px;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #5B768B !important;
      margin: unset !important;

      &__offer {
        width: 212px;
      }

      span {
        padding: unset !important;
      }
    }
  }
}
