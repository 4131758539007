.wrapper {
	width: 100%;
	height: 100%;
	display: flex;
    flex-direction: column;
    align-items: center;
}
.logo {
	height: auto;
	// width: 100%;
	max-width: 722px;
	max-height: 80vh;
}
