@import "~assets/styles/variables";

.statusesFilters {
  animation: FiltersContainer_opacityToVisible__fy8gk 0.2s linear;
  margin: 120px 0 24px 0;
  max-width: 360px;
  min-width: 360px;
  width: 100%;
  height: max-content;
  position: sticky;
  top: -26px;
  background-color: transparent;
  z-index: 10;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow-y: auto;

  .separator {
    width: 100%;
    border: none;
    height: 1px;
    background-color: $primary-bg;
  }

  &__form {
    padding: 10px 16px;

    &_actions {
      display: flex;
      justify-content: center;
    }
  }
}