@import '~assets/styles/variables';

.menuItem {
  padding: 8px 12px !important;
  transition: background-color 0.3s ease !important;

  &:hover {
    border-radius: 8px;
    background-color: $primary-light02 !important;
  }
}

.actionsList {
  padding: unset !important;
}

.paper {
  box-shadow: 0px 0px 20px 0px rgba(33, 75, 165, 0.15) !important;
  border-radius: 8px !important;
  border: 1px solid $primary-light !important;
}

.active {
  path {
    fill: #214ba5;
  }
}

.actionsContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 116px;

  .actionsText {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #1c2f4c;
  }
}

.dialog {
  position: relative;

  &__header {
    display: flex;
    justify-content: end;
    padding: 24px 24px 22px 24px;

    &_button {
      padding: 0;
    }
  }

  &__content {
    padding: 0 52px 52px 52px;
  }
}