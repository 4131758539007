@import '~assets/styles/variables';
@import '~assets/styles/mixins';


.donationTable {
  border-collapse: collapse;
  position: relative;
  overflow: unset;
  width: 100%;

  &_wrapper {
    width: 100%;
    min-height: calc(100% - 200px);
    height: 100%;
    overflow-y: hidden;
    overflow-x: auto;
  }

  &_thead {
    height: 40px;

    th {
      background-color: #F1F3FF;
      padding: 10px 16px;
      color: #214ba5;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.208px;
      cursor: default;
    }

    &_withSort {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;

      &_icon {
        display: flex;
        flex-direction: column;
        height: 16px;

        svg path {
          fill: #AFB7FB;
        }
      }

      &_ASC, &_DESC {
        path {
          fill: $primary-main !important;
        }
      }
    }

    &_photo {
      border-radius: 8px 0 0;
    }

    &_tooltip {
      top: -14px !important;

      &_content {
        display: flex;
        flex-direction: column;

        font-family: 'Rubik', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.3px;
        text-align: center;

        color: #1C2F4C;
      }
    }

    &_coins {
      &__wrapper {
        display: flex;
        align-items: center;
        width: max-content;
        gap: 4px
      }

      &__topltip {
        width: 575px;
        z-index: 555;

        & > div {
          max-width: 100%;
          width: 100%;

          & > span {
            left: 264px !important;
            bottom: -9px !important;
          }
        }
      }
    }

    &_totalDonations {
      &__topltip {
        width: 273px;
        z-index: 555;

        & > div {
          max-width: 100%;
          width: 100%;

          & > span {
            bottom: -9px !important;
          }
        }
      }
    }

    &_actions {
      border-radius: 0 8px 0 0;
    }
  }

  &_tbody {
    tr {
      height: 100px;
      border-top: 1px solid white;
      border-bottom: 1px solid white;
    }

    td {
      padding: 10px 16px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.208px;
      color: $text-main;

      &:first-child {
        padding: 10px;
      }
    }

    &_suspended {
      td {
        background-color: $primary-light-orange-main-bg;
      }
    }

    &_title {
      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 131%;
        letter-spacing: -0.14px;

        @include multilineThreeDots(1)
      }
    }


    &_visibility {
      svg {
        cursor: pointer;
      }
    }
  }

  &_thead, &_tbody {
    &_photo {
      min-width: 100px;
    }

    &_title {
      min-width: 360px;
      max-width: 360px;
    }

    &_type {
      min-width: 240px;
      max-width: 240px;
    }

    &_coins {
      min-width: 208px;
    }

    &_totalDonations {
      min-width: 208px;
    }

    &_visibility {
      min-width: 214px;
    }
    &_actions {
      min-width: 58px;
      max-width: 58px;

    }
  }

  &__success, &__error {
    display: inline-flex;
    padding: 8px 12px;
    align-items: flex-start;
    gap: 8px;
    width: 196px;
    height: 51px;

    border-radius: 8px;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      &_title {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      &_message {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  &__success {
    border: 1px solid #5CC469;
    background: #DBF4E9;
    width: 274px;

    &__content {
      &_title {
        color: #6C6E7E;
      }

      &_message {
        color: #6C6E7E;
      }
    }
  }

  &__error {
    border: 1px solid #C32B2B;
    background: #FEE;


    &__content {
      &_title {
        color: #6C6E7E;
      }

      &_message {
        color: #6C6E7E;
      }
    }
  }
}

.overflow__hidden {
  overflow: hidden !important;

  & table {
    display: none;
  }
}

.overflow_y__hidden {
  overflow-y: hidden !important;
}

