.tableActions {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__button {
    width: 103px !important;
    height: 44px !important;
  }

  &__download {
    padding: 10px 16px;

    text-align: center;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 131%;
    letter-spacing: -0.18px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    color: #214BA5;

    cursor: pointer;
  }
}
