@import '~assets/styles/mixins';

.volunteer {
	width: 100%;
	display: flex;
	flex-direction: column;

	& > button {
		margin-top: 11px;
	}

	&.options_disable_mode {
		min-height: auto;

		.volunteer__info {
			&__photo {
				height: 40px;
				width: 40px;
			}

			&__photos {
				img {
					height: 40px;
					width: 40px;
				}

				span {
					left: 75px;
				}
			}
		}
	}

	&__info {
		min-height: 22px;
		display: flex;
		align-items: flex-start;
		font-size: 14px;
		line-height: 18px;

		&_name {
			display: flex;
			flex-direction: column;

			span {
				@include twoLineText;
			}
		}

		&__photo {
			display: block;
			width: 30px;
			height: 30px;
      min-width: 30px;
      min-height: 30px;
			border-radius: 50%;
			margin-right: 10px;
		}

		&__photos {
			display: flex;
			align-items: center;
			position: relative;

			img {
				margin-right: 0;
				width: 26px;
				height: 26px;
				border: 1px solid #fff;
				object-fit: cover;

				&:nth-child(2),
				&:nth-child(3) {
					position: relative;
					margin-left: -13px;
				}
			}

			&.two_photos {
				& > img {
					position: relative;

					&:nth-child(2) {
						left: 4px;
					}
				}
			}
		}

		&__counter {
			position: relative;
			margin-left: 12px;
			// left: 55px;
			font-size: 12px;
			line-height: 13px;
			color: #a1b5ca;
		}
	}

	&__options {
		max-width: 130px;
		width: 100%;
		display: flex;
		margin-top: 11px;

		& > svg {
			display: block;
			margin-right: 8px;
			cursor: pointer;
			border-radius: 50%;
		}
	}
}
