.content {
	max-width: 800px;
	width: 100%;

	&__header {
		max-width: 800px;
		width: 100%;
		margin-bottom: 2.6vh;
		display: flex;
		flex-direction: column;

		&__chesed {
			display: block;
			font-size: 14px;
			line-height: 18px;
			letter-spacing: -0.208px;
			max-width: 540px;
		}

		button {
			margin-left: auto;
		}
	}
}
