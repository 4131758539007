@import './keyframes';
@import '~assets/styles/variables';

//page
@mixin orgPage {
	padding: 6.55vh 3.13vw;
	overflow-y: auto;
	overflow-x: hidden;
	height: 100%;
}

//button
@mixin default-button {
	max-width: 134px;
	width: 100%;
	height: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	border-radius: 10px;
	cursor: pointer;
	outline: none;
}
@mixin font-button {
	font-family: 'Rubik', sans-serif;
	font-weight: normal;
	font-size: 18px;
	line-height: 21px;
	letter-spacing: -0.3px;
}
@mixin skipButton {
	position: absolute;
	top: 34px;
	right: 34px;
	padding: 0 5px;
	color: $primary-main;
	text-decoration: none;
}

//input
@mixin inputMain {
	padding: 8px 40px 8px 16px;
	width: 100%;
	outline: none;
	border: 1px solid $primary-light;
	border-radius: 30px;
	font-size: 18px;
	line-height: normal;
	transition: all 300ms;

	&::placeholder {
		color: $text-light;
		padding-top: 12px;
	}

	&:focus {
		border-color: #ffa133;
		transition: border-color 300ms;
	}

	&:disabled {
		background-color: $disabled;
		color: #b1b7bb;
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		box-shadow: 0 0 0 50px white inset !important;
		font: 400 18px 'Rubik';
		color: $text-main;
	}
}
@mixin inputEdit {
	position: absolute;
	right: 16px;
	top: 12px;
	width: 16px;
	height: 16px;
	opacity: 0;
	transition: 0.2s;
	background-image: url('../image/inputs/edit.svg');
	cursor: pointer;
}
@mixin inputTextArea {
	padding: 11px 40px 15px 24px;
	width: 100%;
	height: 100%;
	outline: none;
	border: 1px solid $primary-light;
	border-radius: 30px;
	font-size: 18px;
	line-height: normal;
	transition: all 300ms;
	resize: none;

	&::placeholder {
		color: $text-light;
	}

	&:focus {
		border-color: #ffa133;
		transition: border-color 300ms;
	}

	&:not(:focus) {
		& + .input_textarea__edit {
			opacity: 1;
		}
	}

	&:disabled {
		background-color: $disabled;
		color: #b1b7bb;
	}
}

//animations
@mixin changeOpacityToVisibleAnimation {
	animation: opacityToVisible 0.2s linear;
}
@mixin changeOpacityToUnvisibleAnimation {
	animation: opacityToUnvisible 0.2s linear;
}
@mixin changeTransformFromRight {
	animation: transformFromRight 0.2s linear;
}
@mixin changeTransformToRight {
	animation: transformToRight 0.2s linear;
}

//scroll
@mixin scrollDisable {
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
}

@mixin scrollStyle {
	/* width */
	&::-webkit-scrollbar {
		width: 50px;
	}
	/* Track */
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}
	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #888;
	}
}

//form
@mixin loginContent {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}
@mixin onboardingForm {
	max-width: 335px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}
@mixin signUp-subtitle {
	margin-top: 8px;
	font-size: 18px;
	line-height: 23px;
	letter-spacing: -0.3px;
}
@mixin inputContainer {
	width: 100%;
	height: 75px;
	display: flex;
	flex-direction: column;
	margin-bottom: 2.8vh;
	position: relative;

	label {
		display: block;
		margin-bottom: 15px;
		font-weight: 500;
		font-size: 18px;
		letter-spacing: -0.3px;
		line-height: 23px;
	}
}
@mixin form-error-hint {
	font-size: 12px;
	line-height: 20px;
	letter-spacing: -0.2px;
	color: #d00074;
	margin-left: 24px;
	@include changeOpacityToVisibleAnimation;
}
@mixin popupLabel {
	display: block;
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: -0.3px;
	margin-bottom: 10px;
}

@mixin twoLineText {
	overflow: hidden;
	word-break: break-word;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	display: -webkit-box;
}

//deprecated
@mixin threeDots($w: 200px, $h: 21px) {
	max-width: $w;
	height: $h;
	display: inline-block;
	vertical-align: middle;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

@mixin lineThreeDots() {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

@mixin multilineThreeDots($lineClamp: 2) {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: $lineClamp;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  max-width: 600px;
}
